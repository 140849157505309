import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleWebPortfolio } from "../../../redux/slice/webPortfolioSlice";
import { baseURL } from "../../../utils/config";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";
import Title from "../../Extra/Title/Title";

const PortfolioShow = () => {
  const { portflioSingleShow } = useSelector((state) => state.webPortfolio);

  const { portfolioName } = useParams();

  const dispatch = useDispatch();
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(getSingleWebPortfolio(portfolioName));
  }, []);

  console.log("portfolioName", portfolioName);
  useEffect(() => {
    setData(portflioSingleShow);
  }, [portflioSingleShow]);
  return (
    <div>
      <WebBanner
        pageTitle={`PORTFOLIO`}
        title={`Portfolio of Interactive Web Experiences`}
        highlightText={["Portfolio"]}
      />
      <section className="portfolioShow p-100-y">
        <div className="startPortfolioCard">
          <div className="container-md">
            <Title
              minText={data?.portfolioCategory?.categoryName}
              text={data?.title}
            />
            <div className="portfoliMainBanner height-xxxl-750 height-xxl-650 border-radius-14 overflow-hidden m-50-top">
              <img
                src={baseURL + data?.thumbnail}
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="showPortfolioData bg-third p-lg-60-y p-sm-30-y p-10-y m-lg-80-y m-sm-50-y m-30-y ">
            <div className="container-md">
              <div
                className="mainBannerText text-light fs-xxl-22 fs-md-20 fs-sm-16 fs-14 lh-md-50 lh-sm-40 lh-30"
                dangerouslySetInnerHTML={{
                  __html: data.titleHtml,
                }}
              ></div>
            </div>
          </div>
          <div className="container-md">
            <div className="portfolioGallery p-50-y">
              <div className="row">
                {data?.portfolioImages?.map((res) => (
                  <div className="col-xxl-3 col-lg-4 col-sm-6 col-12 m-25-bottom">
                    <div className="portfolioBanner height-500 border-radius-5 overflow-hidden">
                      <img src={baseURL + res} alt="Portfolio Images" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PortfolioShow;
