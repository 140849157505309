import "./button.scss";

const Button = ({ onClick, text, aIcon, bIcon, icon, className, type }) => {
  return (
    <button
      type={type}
      className={`themeButton ${className} cursor-pointer`}
      onClick={onClick}
    >
      {bIcon && (
        <span>
          <i className={`${bIcon} m5-right`}></i>
        </span>
      )}
      {text && <span>{text}</span>}
      {icon && (
        <span>
          <i className={`${icon}`}></i>
        </span>
      )}
      {aIcon && (
        <span>
          <i className={`${aIcon} m5-left`}></i>
        </span>
      )}
    </button>
  );
};

export default Button;
