import Slider from "react-slick";
import Title from "../../../Extra/Title/Title";
import "./review.scss";

const Review = ({ text, minText, data }) => {
  const options = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="clientReview p-80-y">
      <Title minText={minText} text={text} />

      <div className="row justify-content-center p-70-y">
        <div className="col-xxxl-6 col-xxl-7 col-xl-8 col-md-9 col-sm-10 col-11">
          <Slider className="owl-theme" {...options} id="clientReview">
            {data.map((res) => (
              <div className="item">
                <div className="reviewDetails">
                  <div className="reviewText text-gray fs-lg-20 fs-sm-18 fs-14 lh-sm-40 lh-30 text-overflow-md-4 text-overflow-5">
                    {res.reviewText}
                  </div>
                  <div className="userDetails m-20-top d-flex align-items-center">
                    <div className="userImage hw-60 border-round-50 overflow-hidden">
                      <img src={res.userImage} alt="" />
                    </div>
                    <div className="userName fw-600 fs-xxl-22 fs-md-20 fs-sm-16 fs-14 m-20-left">
                      {res.userName}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default Review;
