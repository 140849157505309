import React, { useEffect, useState } from "react";
import Title from "../../Extra/Title/Title";
import Technoloy1 from "../../../assets/images/technoloy1.png";
import Technoloy2 from "../../../assets/images/technoloy2.png";
import Technoloy3 from "../../../assets/images/technoloy3.png";
import Technoloy4 from "../../../assets/images/technoloy4.png";
import Technoloy5 from "../../../assets/images/technoloy5.png";
import Technoloy6 from "../../../assets/images/technoloy6.png";
import Technoloy7 from "../../../assets/images/technoloy7.png";
import Technoloy8 from "../../../assets/images/technoloy8.png";
import Technoloy9 from "../../../assets/images/technoloy9.png";
import Technoloy10 from "../../../assets/images/technoloy10.png";
import Technoloy11 from "../../../assets/images/technoloy11.png";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { webTechnologyGet } from "../../../redux/slice/webTechnologySlice";
import { baseURL } from "../../../utils/config";

const TechnologyHome = () => {
  const { webTechnology } = useSelector((state) => state.webTechnology);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(webTechnologyGet());
  }, []);

  useEffect(() => {
    setData(webTechnology);
  }, [webTechnology]);

  return (
    <section className="technologyHome p-100-y text-center bg-third">
      <div className="container-md">
        <div className="row">
          <div className="col-xl-5 col-sm-6 col-12 order-sm-0 order-1">
            <div className="technologyDetails">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-6">
                  {data.map(
                    (res, i) =>
                      i % 2 == 0 && (
                        <TechnologyCategories
                          image={res.image}
                          title={res.technologyName}
                          color={res.technologyColor}
                        />
                      )
                  )}
                </div>
                <div className="col-lg-6 col-6 m-sm-100-top">
                  {data.map(
                    (res, i) =>
                      i % 2 == 1 && (
                        <TechnologyCategories
                          image={res.image}
                          title={res.technologyName}
                          color={res.technologyColor}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-sm-6 col-12 order-sm-1 order-0">
            <div className="position-sticky top-25">
              <Title
                minText={`OUR EXPERTISE`}
                text={`Our Technology Stack`}
                textAlign={`text-sm-start text-center`}
                fontsClass={`text-light`}
              />
              <div className="text-gray text-sm-start text-center fs-lg-16 fs-14 lh-35 m-xl-150-left m-sm-50-left m-sm-50-top m-10-top m-sm-0-bottom m-50-bottom">
                We all know that nothing moves until someone makes a decision.
                The first action is always in making the decision to proceed.
                which most people overlook, we don’t do it intentionally or with
                malice.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologyHome;

export const TechnologyCategories = ({ image, title, color }) => {
  const TechnologyImage = styled.div`
    .catrgoryOfTechnology {
      .technologyCategory {
        background-color: ${(props) => props.color};
      }
      &:hover {
        ${'' /* box-shadow: 0 0px 26px ${(props) => `${props.color}50`}; */}
        border-color: transparent !important;
        background-color: ${(props) => props.color};
        .technologyCategory {
          &::before {
            border: 3px solid ${(props) => props.color};
          }
        }
      }
    }
  `;

  return (
    <TechnologyImage color={color}>
      <div className="catrgoryOfTechnology cursor-pointer border-solid-second-1 border-radius-20 p-30-y h-100 p-30-x trans-3 m-30-bottom">
        <div
          className="technologyImage technologyCategory hw-xl-110 hw-70 p-xl-25 p-20 border-round-50 m-auto"
          style={{
            backgroundColor: color,
          }}
        >
          <img src={baseURL + image} alt="technology" />
        </div>
        <div className="fw-600 fs-xxl-20 fs-xl-18 fs-16 m-xl-30-top m-sm-20-top m-15-top text-light">
          {title}
        </div>
      </div>
    </TechnologyImage>
  );
};
