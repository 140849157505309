import React from "react";
import Error404Page from "../../../assets/images/404.png";
import Button from "../../Extra/Button/Button";
import { Link } from "react-router-dom";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";

const Error404 = () => {
  return (
    <div>
      <WebBanner
        pageTitle={`ERROR`}
        title={`Mastering Web Errors 404 and Debugging`}
        highlightText={["Errors 404"]}
      />
      <div className="row justify-content-center p-100-y">
        <div className="col-xxl-6 col-lg-8 col-11">
          <div className="errorPage text-center">
            <div className="fs-xxxl-40 fs-md-30 fs-sm-26 fs-20 fw-600">
              This page is outside of the universe
            </div>
            <div className="fs-md-18 fs-sm-16 fs-14 lh-30 p-30-y text-gray">
              The page you are trying to access doesn't exist or has been moved.
              Try going back to our homepage.
            </div>
            <Link to={`/`}>
              <Button text={`Go to homepage`} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
