import React from "react";
import "./home.scss";
import AboutHome from "./AboutHome";
import CourseHome from "./CourseHome";
import ServiceHome from "./ServiceHome";
import TechnologyHome from "./TechnologyHome";
import PortfolioHome from "./PortfolioHome";
import ContactHome from "./ContactHome";
import TopSlider from "./TopSlider";

const Home = () => {
  return (
    <div>
      <TopSlider />
      <AboutHome />
      <ServiceHome />
      <CourseHome />
      <TechnologyHome />
      <ContactHome />
    </div>
  );
};

export default Home;
