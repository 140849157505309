import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";

const initialState = {
  webCourseFacility: [],
  isLoading: false,
  isSkeleton: false,
};
export const webCourseFacilityGet = createAsyncThunk(
  "webCourseFacility/webCourseFacilityGet",
  async (payload) => {
    return apiInstance.get(`webCourseFacility/webCourseFacilityGet`);
  }
);

const webCourseFacilitySlice = createSlice({
  name: "webCourseFacilitySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webCourseFacilityGet
    builder.addCase(webCourseFacilityGet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webCourseFacilityGet.fulfilled, (state, action) => {
      state.webCourseFacility = action.payload.webCourseFacility;
      state.isLoading = false;
    });
    builder.addCase(webCourseFacilityGet.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webCourseFacilitySlice.reducer;
