import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";

const initialState = {
  webTechnology: [],
  isLoading: false,
  isSkeleton: false,
};
export const webTechnologyGet = createAsyncThunk(
  "webTechnology/webTechnologyGet",
  async (payload) => {
    return apiInstance.get(`webTechnology/webTechnologyGet`);
  }
);

const webTechnologySlice = createSlice({
  name: "webTechnologySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webTechnologyGet
    builder.addCase(webTechnologyGet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webTechnologyGet.fulfilled, (state, action) => {
      state.webTechnology = action.payload.webTechnology;
      state.isLoading = false;
    });
    builder.addCase(webTechnologyGet.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webTechnologySlice.reducer;
