import React from "react";
import "./title.scss";
import Highlights from "../Highlights";

const Title = ({
  minText,
  text,
  textCase = "text-uppercase",
  className,
  highlights,
  textAlign = "text-center",
  fonts = "fs-xxl-70 fs-lg-55 fs-md-40 fs-30 lh-xxl-90 lh-lg-75 lh-md-50 lh-40 fw-700",
  fontsClass,
}) => {
  return (
    <div className={`${textAlign} ${textCase} ${className}`}>
      <div className="row justify-content-center">
        <div className="col-12">
          {minText && (
            <div
              className="titleMin text-primeDark m-20-bottom fw-500 d-inline-block p-10-x p-2-y"
              style={{ letterSpacing: "8px" }}
              data-fill-text={minText}
            >
              <div>{minText}</div>
            </div>
          )}
          <Highlights
            text={text}
            className={`${fontsClass} ${fonts}`}
            highlights={highlights}
          />
        </div>
      </div>
    </div>
  );
};

export default Title;
