import React, { useEffect, useState } from "react";
import CourseRoadMap from "./CourseRoadMap";
import psImg from "../../../assets/images/roadmap/psImg.svg";
import aiImg from "../../../assets/images/roadmap/aiImg.svg";
import htmlImg from "../../../assets/images/roadmap/htmlImg.svg";
import cssImg from "../../../assets/images/roadmap/cssImg.svg";
import bootstrapImg from "../../../assets/images/roadmap/bootstrapImg.svg";
import jsImg from "../../../assets/images/roadmap/jsImg.svg";
import jqueryImg from "../../../assets/images/roadmap/jqueryImg.svg";
import scssImg from "../../../assets/images/roadmap/scssImg.svg";
import projectImg from "../../../assets/images/roadmap/projectImg.svg";
import Title from "../../Extra/Title/Title";
import CourseAccordion from "./CourseAccordion";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleWebCourse } from "../../../redux/slice/webCourseSlice";
import { baseURL } from "../../../utils/config";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";

const CourseShow = () => {
  const { courseSingleShow } = useSelector((state) => state.webCourse);

  const { courseName } = useParams();

  const dispatch = useDispatch();
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(getSingleWebCourse(courseName));
  }, []);

  useEffect(() => {
    setData(courseSingleShow);
  }, [courseSingleShow]);

  console.log("courseName", courseName);

  return (
    <div>
      <WebBanner
        pageTitle={`COURSES`}
        title={`Web Developer's Toolkit: Skills for Success`}
        highlightText={["Skills for Success"]}
      />
      {data && (
        <div className="m-100-y">
          <div className="container-md">
            <section className="courseShow p-50-sm-y p-20-y">
              <Title minText={data.courseCategory} text={data.headTitle} />
              <div className="text-center fs-lg-18 fs-sm-16 fs-14 lh-lg-40 lh-30 fw-400 text-gray p-lg-30-y p-15-y">
                {data.tagDescription}
              </div>

              <div className="courseMainBanner height-xxl-650 border-radius-14 overflow-hidden m-20-y">
                <img
                  src={baseURL + data.image}
                  alt="courseImage"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </section>
            <section className="aboutCourseDetails p-100-bottom">
              <div className="courseDetails text-gray">
                <div className="p-20-y fw-700 fs-xxl-30 fs-xl-28 fs-lg-24 fs-md-22 fs-18 text-third">
                  {data.title}
                </div>
                <div
                  className="fs-lg-20 fs-sm-18 fs-14 lh-sm-40 lh-25 text-gray"
                  dangerouslySetInnerHTML={{
                    __html: data.titleHtml,
                  }}
                ></div>
                <div className="p-20-y fw-700 fs-xxl-30 fs-xl-28 fs-lg-24 fs-md-22 fs-18 text-third">
                  What You'll Learn:
                </div>
                <ul className="fs-lg-20 fs-sm-18 fs-14 lh-sm-40 lh-25 text-gray">
                  {data?.learnIn?.map((txt) => (
                    <li className={`${!txt.learnTitle && "p-10-y"}`}>
                      {txt.learnTitle && <b>{txt.learnTitle}:</b>}{" "}
                      {txt.learnDescription}
                    </li>
                  ))}
                </ul>
                <div className="text-third p-20-y fw-700 fs-xxl-30 fs-xl-28 fs-lg-24 fs-md-22 fs-18">
                  Durations:
                </div>
                <ul className="fs-lg-20 fs-sm-18 fs-14 lh-sm-40 lh-25 fw-700 text-gray">
                  <li className="d-flex">
                    <div>Course Duration :</div>
                    <div className="text-primeDark m-10-left">
                      {data?.duration}{" "}
                    </div>
                  </li>
                  <li className="d-flex">
                    <div>Lecture Duration :</div>
                    <div className="text-primeDark m-10-left">
                      {" "}
                      {data?.hours}{" "}
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className="courseRoadMap bg-third">
            <CourseRoadMap data={data.roadMapData} />
          </div>
          <div className="container-md">
            <CourseAccordion data={data.courseOpportunities} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseShow;
