import React from "react";
import "./header.scss";
import TitleHeader from "./TitleHeader";
import Navigation from "./Navigation";
import TopSlider from "../../components/Home/TopSlider";
const Header = () => {
  return (
    <header className="headerArea">
      <div className="bg-third">
        <TitleHeader />
        <Navigation />
      </div>
    </header>
  );
};

export default Header;
