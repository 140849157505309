import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";


const initialState = {
  webSlider: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebSlider = createAsyncThunk(
  "webSlider/getWebSlider",
  async (payload) => {
    return apiInstance.get(`webSlider/getWebSlider`);
  }
);

const webSliderSlice = createSlice({
  name: "webSliderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebSlider
    builder.addCase(getWebSlider.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getWebSlider.fulfilled, (state, action) => {
      state.webSlider = action.payload.webSlider;
      state.isLoading = false;
    });
    builder.addCase(getWebSlider.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webSliderSlice.reducer;
