import React from "react";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";
import About1 from "../../../assets/images/about/about1.png";
import About2 from "../../../assets/images/about/about2.png";
import About3 from "../../../assets/images/about/about3.png";
import About4 from "../../../assets/images/about/about4.png";
import Highlights from "../../Extra/Highlights";
import Title from "../../Extra/Title/Title";
import { Link } from "react-router-dom";
import ContactHome from "../Home/ContactHome";

const Contact = () => {
  return (
    <div>
      <WebBanner
        pageTitle={`CONTACT US`}
        title={` Get in Touch for Digital Solutions`}
        highlightText={["Digital Solutions"]}
      />
      <section className="serviceHome p-100-y">
        <div className="container-md">
          <div className="row">
            <div className="col-xxxl-6 col-sm-6 col-12 ">
              <div className="position-sticky top-25">
                <Title
                  minText={`CONTACT US`}
                  text={`Get in touch with us`}
                  textAlign={`text-sm-start text-center`}
                />
                <div className="text-second text-sm-start text-center fs-lg-16 fs-14 lh-35 m-xl-150-left m-sm-50-left m-sm-50-top m-10-top m-sm-0-bottom m-50-bottom">
                  We all know that nothing moves until someone makes a decision.
                  The first action is always in making the decision to proceed.
                  which most people overlook, we don’t do it intentionally or
                  with malice.
                </div>
              </div>
            </div>
            <div className="col-xxxl-4 col-sm-6 col-12 ">
              <div className="serviceDetails">
                <div className="companyDetails bg-primeLight p-20 border-radius-10 cursor-pointer d-flex">
                  <div className="companyDetailsIcon fs-lg-40 fs-24 text-primeDark m-10-right">
                    <i class="ph ph-map-pin"></i>
                  </div>
                  <div className="companyDetailsText">
                    <div className="text-primeDark fw-500 m-10-bottom fs-xl-26 fs-lg-22 fs-md-18 fs-14 lh-xxl-45">
                      Office Address
                    </div>
                    <div className="footerSubDetail m-5-top fw-500">
                      <Link
                        className="text-second fs-lg-22 fs-md-18 fs-14 lh-md-45 lh-30"
                        target="_blank"
                        to={`https://maps.app.goo.gl/cY6vNxuNJhnb8GDo9`}
                      >
                        520, Sahajanand Hub, Gaghpur Road, Opp. N.C. Takker
                        School, Sarathana Jakatnaka, Surat - 395006.
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="companyDetails bg-primeLight p-20 border-radius-10 cursor-pointer m-sm-50-y m-30-y d-flex">
                  <div className="companyDetailsIcon fs-lg-40 fs-24 text-primeDark m-10-right">
                    <i class="ph ph-envelope-simple-open"></i>
                  </div>
                  <div className="companyDetailsText">
                    <div className="text-primeDark fw-500 m-10-bottom fs-xl-26 fs-lg-22 fs-md-18 fs-14 lh-xxl-45">
                      Email Us
                    </div>
                    <div className="footerSubDetail text-second m-5-top fw-500">
                      <Link
                        className="text-second fs-lg-22 fs-md-18 fs-14 lh-md-45 lh-30"
                        target="_blank"
                        to={`mailto:multikodit@gmail.com`}
                      >
                        multikodit@gmail.com
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="companyDetails bg-primeLight p-20 border-radius-10 cursor-pointer d-flex">
                  <div className="companyDetailsIcon fs-lg-40 fs-24 text-primeDark m-10-right">
                    <i class="ph ph-phone"></i>
                  </div>
                  <div className="companyDetailsText">
                    <div className="text-primeDark fw-500 m-10-bottom fs-xl-26 fs-lg-22 fs-md-18 fs-14 lh-xxl-45">
                      Call Us
                    </div>
                    <div className="footerSubDetail text-second m-5-top fw-500">
                      <Link
                        className="text-second fs-lg-22 fs-md-18 fs-14 lh-md-45 lh-30"
                        target="_blank"
                        to={`tel:8866625957`}
                      >
                        <div>(+91) 88666 25957</div>
                      </Link>
                      <Link
                        className="text-second fs-lg-22 fs-md-18 fs-14 lh-md-45 lh-30"
                        target="_blank"
                        to={`tel:6358163236`}
                      >
                        <div>(+91) 63581 63236</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactHome />
    </div>
  );
};

export default Contact;

