import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";

const initialState = {
  webPortfolio: [],
  webPortfolioCategory: [],
  portflioSingleShow: {},
  isLoading: false,
  isSkeleton: false,
};
export const getWebPortfolioCategoryWise = createAsyncThunk(
  "webPortfolioCategory/getWebPortfolioCategoryWise",
  async (payload) => {
    return apiInstance.get(`webPortfolioCategory/getWebPortfolioCategoryWise`);
  }
);
export const getSingleWebPortfolio = createAsyncThunk(
  "webPortfolio/getSingleWebPortfolio",
  async (portflioName) => {
    return apiInstance.get(`webPortfolio/getSingleWebPortfolio?portfolioLink=${portflioName}`);
  }
);

const webPortfolioSlice = createSlice({
  name: "webPortfolioSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebPortfolioCategoryWise
    builder.addCase(getWebPortfolioCategoryWise.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getWebPortfolioCategoryWise.fulfilled, (state, action) => {
      state.webPortfolio = action.payload.webPortfolio;
      state.webPortfolioCategory = action.payload.webPortfolioCategory;
      state.isLoading = false;
    });
    builder.addCase(getWebPortfolioCategoryWise.rejected, (state, action) => {
      state.isLoading = false;
    });
    // getSingleWebPortfolio
    builder.addCase(getSingleWebPortfolio.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleWebPortfolio.fulfilled, (state, action) => {
      state.portflioSingleShow = action.payload.webPortfolio;
      state.isLoading = false;
    });
    builder.addCase(getSingleWebPortfolio.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webPortfolioSlice.reducer;
