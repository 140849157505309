import React, { useState } from "react";
import Title from "../../Extra/Title/Title";
import "./courses.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWebCourseCategoryWithCourseName } from "../../../redux/slice/webCourseCategorySlice";

const CoursesAll = () => {
  const { courseCategory } = useSelector((state) => state.webCourseCategory);

 

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getWebCourseCategoryWithCourseName());
  }, []);

  useEffect(() => {
    setData(courseCategory);
  }, [courseCategory]);

  const [selectCategory, setSelectCategory] = useState(0);

  return (
    <section className="mainCourseDetails p-90-y">
      <Title
        minText={`IT CORE ELEMENTS`}
        text={`Select the Ideal Course for Career Growth`}
      />

      <div className="showAllCourse m-50-y">
        <div className="row">
          <div className="col-xl-4 col-sm-6 col-12 m-30-bottom">
            <div className="showCourseTopic bg-light themeBoxShadow text-center border-radius-20 p-xxl-30-y p-10-y p-xxxl-40-x p-xxl-30-x p-sm-20-x p-10-x">
              <ul className="mainTopic trans-3 fw-700 cursor-pointer fs-xxxl-18 fs-xxl-16 fs-lg-14 fs-sm-12 fs-xsm-14 fs-12">
                {data.map((res, i) => (
                  <li
                    className={`p-xxl-30-y p-20-y text-uppercase ${
                      selectCategory == i && "text-primeDark"
                    }`}
                    onClick={() => setSelectCategory(i)}
                  >
                    {res?.categoryName}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-xl-8 col-sm-6 col-12 m-30-bottom">
            <div className="showPetaCourse text-center">
              <div
                className="text-gray text-nowrap fs-xxxl-20 fs-xxl-18 fs-xsm-16 fs-14 m-30-bottom fw-700 text-uppercase"
                style={{ letterSpacing: "2px" }}
              >
                {data[selectCategory]?.categoryName}
              </div>
              <div className="row fs-xxxl-20 fs-xxl-18 fs-lg-16 fs-sm-14 fs-xsm-16 fs-14 fw-500">
                {data[selectCategory]?.innterCourse.length > 0 ? (
                  data[selectCategory]?.innterCourse?.map((res) => (
                    <div className="col-xl-6 col-12 m-24-bottom">
                      <Link to={`/courses/${res.courseLink}`}>
                        <div className="courseName h-100 text-dark border-bottom-solid-prime-2 border-top-solid-prime-2 bg-light border-radius-10 cursor-pointer courseBoxShadow p-20-y p-xxxl-30-x p-md-20-x p-10-x">
                          {res?.headTitle}
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <div className="col-6 m-auto">
                    <div className="courseName h-100 text-dark border-bottom-solid-prime-2 border-top-solid-prime-2 bg-light border-radius-10 cursor-pointer courseBoxShadow p-20-y p-xxxl-30-x p-md-20-x p-10-x">
                      No Course Found !
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoursesAll;
