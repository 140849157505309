import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";

const initialState = {
  courseCategory: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebCourseCategoryWithCourseName = createAsyncThunk(
  "webCourseCategory/getWebCourseCategoryWithCourseName",
  async (payload) => {
    return apiInstance.get(
      `webCourseCategory/getWebCourseCategoryWithCourseName`
    );
  }
);

const webCourseCategorySlice = createSlice({
  name: "webCourseCategorySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebCourseCategoryWithCourseName
    builder.addCase(
      getWebCourseCategoryWithCourseName.pending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      getWebCourseCategoryWithCourseName.fulfilled,
      (state, action) => {
        state.courseCategory = action.payload.webCourseCategory;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getWebCourseCategoryWithCourseName.rejected,
      (state, action) => {
        state.isLoading = false;
      }
    );
  },
});
export default webCourseCategorySlice.reducer;
