import React from "react";
import "./portfolio.scss";
import VectorTitle from "../../Extra/VectorTitle/VectorTitle";
import PortfolioCard from "./PortfolioCard";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";

const Portfolio = () => {
  return (
    <div>
      <WebBanner
        pageTitle={`PORTFOLIO`}
        title={`Mastering the Art of Career Development`}
        highlightText={["Mastering"]}
      />
      <PortfolioCard />
    </div>
  );
};

export default Portfolio;
