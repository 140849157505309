import React from "react";
import "./webBanner.scss";
import Highlights from "../../../Extra/Highlights";

const WebBanner = ({ pageTitle, title, highlightText }) => {
  return (
    <section className="startHeader bg-third wenBanner p-50-bottom p-30-top">
      <div className="container-md">
        <div className="startTopSlider">
          <div className="mainSliderBox  position-relative">
            <div className="row p-md-150-x p-xsm-60-x p-15-x p-xl-70-y p-sm-20-y w-100">
              <div className="col-12">
                <div
                  className={`sliderText p-lg-40-left text-md-start text-center`}
                >
                  <div
                    className={`headTitle text-light bg-primeDarkLight fs-xxl-20 fs-xl-18 fs-16 lh-xl-40 lh-30 d-inline-block p-15-x fw-500 border-radius-xl-8 border-radius-5 m-lg-30-bottom m-20-bottom`}
                  >
                    {pageTitle}
                  </div>
                  <div className={``}>
                    <Highlights
                      text={title}
                      className={`text-light ls-1 fs-xxl-100 lh-xxl-140 fs-xl-80 lh-xl-100 fs-lg-60 lh-lg-80 fs-40 lh-60 fw-700 text-uppercase`}
                      highlights={highlightText}
                      highlightColor={`text-primeDarkLight`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebBanner;
