import React from "react";
import VectorTitle from "../../Extra/VectorTitle/VectorTitle";
import CourseImage from "../../../assets/images/vector/course.png";
import CoursesAll from "./CoursesAll";
import CourseFacility from "./CourseFacility";
import Review from "../../global_components/Extra-Components/Review/Review";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";
const Courses = () => {
  const studentReviewData = [
    {
      reviewText:
        "make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing. make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.",
      userName: "Karim Al-Fahad, Hong Kong",
      userImage:
        "https://www.profilebakery.com/wp-content/uploads/2024/05/Profile-picture-created-with-ai.jpeg",
    },
    {
      reviewText:
        "make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing. make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.",
      userName: "Yasmin Haddad, UAE",
      userImage:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww",
    },
    {
      reviewText:
        "make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing. make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.",
      userName: "Sofia De Luca, USA",
      userImage:
        "https://img.freepik.com/free-photo/portrait-businesswoman-isolated-home_23-2148813223.jpg",
    },
  ];

  return (
    <div className="custom-container">
      <WebBanner
        pageTitle={`COURSES`}
        title={`Building Scalable Web Apps : Practical Guide`}
        highlightText={["Practical Guide"]}
      />
      <div className="container-md">
        <CoursesAll />
      </div>
      <CourseFacility />

      <div className="container-md">
        <Review
          data={studentReviewData}
          minText={`STUDENT REVIEWS`}
          text={`What Students say about Us`}
        />
      </div>
    </div>
  );
};

export default Courses;
