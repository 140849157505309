import React from "react";
import Title from "../../Extra/Title/Title";
import { baseURL } from "../../../utils/config";

const CourseRoadMap = ({ data }) => {
  console.log("road data", data);
  return (
    <section className="courseRoadMap p-100-y">
      <div className="container-md">
        <div className="startCourseRoadMap">
          <Title text={`Roadmap`} fontsClass={`text-light`} />
          <div className="courseMainBox position-relative m-50-y p-50-y">
            <div className="courseLine position-absolute h-100 width-3 top-0"></div>
            {data?.map((res, i) => (
              <div
                key={i}
                className={`courseTypeMainBox ${
                  i % 2 == 0 ? "courseTypeMainRight" : "courseTypeMainLeft"
                } w-100 p-60-y position-relative`}
              >
                <div className="courseTypeMain d-flex align-items-center position-absolute top-0">
                  <div
                    className="courseTypeCircle position-relative hw-25 border-round-50 border-solid-light-4"
                    style={{
                      background: `radial-gradient(circle, ${res?.task?.taskColor} 40%, #0A0A0A 41%)`,
                    }}
                  ></div>
                  <div className="courseType position-relative bg-dark d-flex align-items-center p-20-y p-30-x border-radius-15">
                    <div className="courseTypeImg hw-lg-50 hw-40">
                      <img
                        src={baseURL + res?.task?.image}
                        alt="Image not Found"
                      />
                    </div>
                    <p className="courseTypeText text-light fw-600 fs-lg-25 fs-20">
                      {res?.task?.taskName}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseRoadMap;
