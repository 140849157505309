import React, { useEffect } from "react";
import Title from "../../Extra/Title/Title";
import $ from "jquery";

const CourseAccordion = ({ data }) => {
  return (
    <div>
      <AccordionEvent val={data} />
      <div className="accordionMainBox m-100-y">
        <Title minText={`AFTER COURSE OPPORTUNITIES`} text={"Opportunities Waiting for YOU!"} />
        <div className="row m-30-top">
          {[0, 1].map((condArray) => (
            <div className="col-sm-6 col-12">
              {data?.map(
                (res, i) =>
                  i % 2 == condArray && (
                    <div className="col-12 m-20-bottom">
                      <div
                        className="accordionBox bg-light border-radius-5 cursor-pointer"
                        key={i}
                      >
                        <div className="accordionHead p-15-y p-20-x d-flex justify-content-between align-items-center ">
                          <div className="accordionHeadText fs-xl-18 fs-16 fw-600 text-customGray">
                            {res.title}
                          </div>
                          <div className="accordionHeadIcon text-customGray fs-18">
                            <i class="fa-solid fa-chevron-down"></i>
                          </div>
                        </div>
                        <div className="accordionBody p-20-x text-customGray">
                          <div className="masterBody p-15-y ">
                            <ul>
                              <li>
                                <p>
                                  <strong>Responsibilities:</strong>{" "}
                                  {res.responsibilities}
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>Tools:</strong> {res.tools}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseAccordion;

export const AccordionEvent = ({ val }) => {
  useEffect(() => {
    $(".accordionBody").hide();

    const handleClickAcco = (e) => {
      const current = $(e.currentTarget);
      $(".accordionBody").not(current.next(".accordionBody")).slideUp();
      current.next(".accordionBody").slideToggle();
    };

    $(".accordionHead").on("click", handleClickAcco);
    return () => {
      $(".accordionHead").off("click", handleClickAcco);
    };
  }, [val]);
  return null;
};
