import React, { useEffect, useRef } from "react";
import "./cursor.scss";

const Cursor = () => {
  const cursorOuterRef = useRef(null);
  const cursorInnerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (cursorOuterRef.current && cursorInnerRef.current) {
        // Update positions
        cursorOuterRef.current.style.left = `${e.clientX}px`;
        cursorOuterRef.current.style.top = `${e.clientY}px`;
        cursorInnerRef.current.style.left = `${e.clientX}px`;
        cursorInnerRef.current.style.top = `${e.clientY}px`;
      }
    };

    const handleMouseHover = (e) => {
      if (cursorInnerRef.current && cursorOuterRef.current) {
        if (e.target.closest(".cursor-pointer")) {
          cursorOuterRef.current.classList.add("cursor-hover");
          cursorInnerRef.current.classList.add("cursor-hover");
        } else {
          cursorOuterRef.current.classList.remove("cursor-hover");
          cursorInnerRef.current.classList.remove("cursor-hover");
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseover", handleMouseHover);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseover", handleMouseHover);
    };
  }, []);

  return (
    <div>
      <div ref={cursorOuterRef} className="mouse-cursor cursor-outer"></div>
      <div ref={cursorInnerRef} className="mouse-cursor cursor-inner"></div>
    </div>
  );
};

export default Cursor;
