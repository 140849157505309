import React, { useEffect, useState } from "react";
import Facility1 from "../../../assets/images/course/facility1.svg";
import Facility2 from "../../../assets/images/course/facility2.svg";
import Facility3 from "../../../assets/images/course/facility3.svg";
import Facility4 from "../../../assets/images/course/facility4.svg";
import Facility5 from "../../../assets/images/course/facility5.svg";
import Facility6 from "../../../assets/images/course/facility6.svg";
import Facility7 from "../../../assets/images/course/facility7.svg";
import Facility8 from "../../../assets/images/course/facility8.svg";
import { useDispatch, useSelector } from "react-redux";
import { webCourseFacilityGet } from "../../../redux/slice/webCourseFacilitySlice";
import { baseURL } from "../../../utils/config";

const CourseFacility = () => {
  const { webCourseFacility } = useSelector((state) => state.webCourseFacility);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(webCourseFacilityGet());
  }, []);

  useEffect(() => {
    setData(webCourseFacility);
  }, [webCourseFacility]);

  return (
    <section className="courseFacility p-60-y bg-third">
      <div className="container-md">
        <div className="facilityShow p-40-y border-radius-20 text-light">
          <div className="row">
            {data.map((res) => {
              const url = baseURL + res.image;
              const imageURL = url.replace(/\\/g, "/");
              return (
                <div className="col-xl-3 col-lg-4 col-smm-6 col-12 m-30-y">
                  <div className="facilityType text-center">
                    <div
                      className="facilityImage hw-xxxl-50 hw-xl-35 hw-lg-30 hw-sm-40 hw-smm-30 hw-40 m-auto m-20-bottom bg-danger"
                      style={{
                        maskImage: `url(${imageURL})`,
                        WebkitMaskImage: `url(${imageURL})`,
                      }}
                    >
                      {/* <img
                        src={baseURL + res.image}
                        alt=""
                        className="h-100"
                        style={{ objectFit: "contain" }}
                      /> */}
                    </div>
                    <div className="facilityName fw-600 fs-xxxl-18 fs-xxl-16 fs-lg-14 fs-sm-16 fs-smm-14 fs-16">
                      {res.facilityName}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseFacility;
