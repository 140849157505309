import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";

const initialState = {
  courseInWeb: [],
  courseSingleShow: {},
  isLoading: false,
  isSkeleton: false,
};
export const getCourseInWeb = createAsyncThunk(
  "webCourse/getCourseInWeb",
  async (payload) => {
    return apiInstance.get(`webCourse/getCourseInWeb`);
  }
);
export const getSingleWebCourse = createAsyncThunk(
  "webCourse/getSingleWebCourse",
  async (courseLink) => {
    return apiInstance.get(
      `webCourse/getSingleWebCourse?courseLink=${courseLink}`
    );
  }
);

const webCourseSlice = createSlice({
  name: "webCourseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getCourseInWeb
    builder.addCase(getCourseInWeb.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCourseInWeb.fulfilled, (state, action) => {
      state.courseInWeb = action.payload.webCourse;
      state.isLoading = false;
    });
    builder.addCase(getCourseInWeb.rejected, (state, action) => {
      state.isLoading = false;
    });

    // getSingleWebCourse
    builder.addCase(getSingleWebCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleWebCourse.fulfilled, (state, action) => {
      state.courseSingleShow = action.payload.webCourse;
      state.isLoading = false;
    });
    builder.addCase(getSingleWebCourse.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webCourseSlice.reducer;
