const TitleHeader = () => {
  return (
    <div className="mainTitleHeader">
      <div className="container-md">
        <div className="startTitleHeader d-flex align-items-center text-center flex-wrap justify-content-center p-10-y">
          <div className="titleHeaderText fs-1 text-third">
            Ready to make some Innovations? We're Hiring!
          </div>
          {/* <div className="titleHeaderBtn bg-prime d-inline text-light p-8-x p-5-y m-10-left border-radius-5 fs-md-16 fs-14 m-sm-0-top m-10-top">
            ✌️Join Our Team
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TitleHeader;
