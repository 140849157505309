import React, { useEffect, useState } from "react";
import "./scrollTop.scss";

// const ScrollTop = () => {
//   const [scrollProgress, setScrollProgress] = useState(0);
//   const [scrollCount, setScrollCount] = useState(0);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollTop =
//         document.documentElement.scrollTop || document.body.scrollTop; // Distance scrolled from the top
//       const scrollHeight =
//         document.documentElement.scrollHeight -
//         document.documentElement.clientHeight; // Total scrollable height

//       setScrollCount(scrollTop);

//       const progress = scrollHeight > 0 ? (scrollTop / scrollHeight) * 100 : 0; // Prevent division by zero
//       setScrollProgress(progress);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };
//   return (
//     <>
//       <div
//         className={`scrollTop position-fixed bottom-30 left-30 z-1 ${
//           scrollCount <= 250 && "d-none"
//         }`}
//       >
//         <div
//           className="scollBtn trns-3 cursor-pointer hw-50 d-flex justify-content-center align-items-center border-solid-primeDark-1 border-round-50 text-light"
//           onClick={scrollToTop}
//           style={{
//             background: `conic-gradient(#ffffff ${scrollProgress}%, #670000 ${scrollProgress}%)`,
//           }}
//         >
//           <i class="fa-solid fa-chevron-up"></i>
//         </div>
//       </div>
//       <div
//         className={`scrollLine height-6 position-fixed top-0 left-0 bg-primeDark`}
//         style={{ width: `${scrollProgress}%` }}
//       ></div>
//     </>
//   );
// };

// export default ScrollTop;
const ScrollToTop = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);

  // Track scroll progress
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      setScrollCount(scrollTop);
      const progress = scrollHeight > 0 ? (scrollTop / scrollHeight) * 100 : 0;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollCount]);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Circle circumference
  const circleCircumference = 307.919;
  const strokeDashoffset = circleCircumference * (1 - scrollProgress / 100);

  return (
    <div
      className={`scroll-to-top position-fixed bottom-30 right-30 z-1 hw-55 cursor-pointer d-block border-radius-50 ${
        scrollCount > 500 && "active-progress"
      }`}
      onClick={scrollToTop}
      title="Scroll to Top"
    >
      <svg
        className="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{
            transition: "stroke-dashoffset 10ms linear",
            strokeDasharray: `${circleCircumference}, ${circleCircumference}`,
            strokeDashoffset,
          }}
        />
      </svg>
    </div>
  );
};

export default ScrollToTop;
