import React, { useEffect, useState } from "react";
import Title from "../../Extra/Title/Title";
import Course1 from "../../../assets/images/course1.svg";
import Course2 from "../../../assets/images/course2.svg";
import Course3 from "../../../assets/images/course3.svg";
import Course4 from "../../../assets/images/course4.svg";
import Course5 from "../../../assets/images/course5.svg";
import Course6 from "../../../assets/images/course6.svg";
import Course7 from "../../../assets/images/course7.svg";
import Course8 from "../../../assets/images/course8.svg";
import Button from "../../Extra/Button/Button";
import { getCourseInWeb } from "../../../redux/slice/webCourseSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../utils/config";
import { Link } from "react-router-dom";

const CourseHome = () => {
  const { courseInWeb } = useSelector((state) => state.webCourse);

  const dispatch = useDispatch();

  const [data, seData] = useState([]);

  useEffect(() => {
    dispatch(getCourseInWeb());
  }, []);
  useEffect(() => {
    seData(courseInWeb);
  }, [courseInWeb]);

  return (
    <section className="courseHome p-50-y text-center">
      <div className="container-md">
        <Title minText={`BEST TRAINING`} text={`Choosing the Perfect Course`} />

        <div className="showCourses m-50-top">
          <div className="row">
            {data.map((res, i) => (
              <div
                key={i}
                className="col-xl-3 col-md-4 col-smm-6 col-12 m-20-bottom"
              >
                <Link to={`/courses/${res.courseLink}`}>
                  <CourseCategory
                    imageIcon={res.icon}
                    courseName={res.name}
                    description={res.description}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <Button text={`Show More Courses`} className={`m-30-top`} />
      </div>
    </section>
  );
};

export default CourseHome;

export const CourseCategory = ({ imageIcon, courseName, description }) => {
  return (
    <div
      className={`courseCategory border-radius-10 text-start p-55-xxxl-x p-30-xl-x p-20-x p-xl-30-y p-20-y h-100 border-solid-lightGray-1 cursor-pointer trans-3`}
    >
      <div className="courseIcon hw-xxxl-75 hw-xxl-70 hw-sm-60 hw-50 bg-primeDark p-md-15 p-12 border-round-50">
        <img
          src={baseURL + imageIcon}
          alt="course"
          style={{ objectFit: "contain" }}
        />
      </div>
      <div className="courseName text-dark m-15-top fw-700 fs-xxl-18 fs-xl-16 fs-14">
        {courseName}
      </div>
      <div className="courseDescription text-gray m-15-top fw-500 lh-lg-30 lh-26 fs-xxl-16 fs-xl-14 fs-12">
        {description}
      </div>
    </div>
  );
};
