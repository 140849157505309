import { configureStore } from "@reduxjs/toolkit";
import loaderSlice from "./slice/loaderSlice";
import webSliderSlice from "./slice/webSliderSlice";
import webCourseSlice from "./slice/webCourseSlice";
import webCourseCategorySlice from "./slice/webCourseCategorySlice";
import webCourseFacilitySlice from "./slice/webCourseFacilitySlice";
import webServiceSlice from "./slice/webServiceSlice";
import webTechnologySlice from "./slice/webTechnologySlice";
import webPortfolioSlice from "./slice/webPortfolioSlice";

// Enable Redux DevTools Extension

const store = configureStore({
  reducer: {
    loader: loaderSlice,
    webSlider: webSliderSlice,
    webCourse: webCourseSlice,
    webCourseCategory: webCourseCategorySlice,
    webCourseFacility: webCourseFacilitySlice,
    webService: webServiceSlice,
    webTechnology: webTechnologySlice,
    webPortfolio: webPortfolioSlice,
  },
});

export default store;
