import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";

const initialState = {
  webService: [],
  isLoading: false,
  isSkeleton: false,
};
export const webServiceGet = createAsyncThunk(
  "webService/webServiceGet",
  async (payload) => {
    return apiInstance.get(`webService/webServiceGet`);
  }
);

const webServiceSlice = createSlice({
  name: "webServiceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webServiceGet
    builder.addCase(webServiceGet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webServiceGet.fulfilled, (state, action) => {
      state.webService = action.payload.webService;
      state.isLoading = false;
    });
    builder.addCase(webServiceGet.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webServiceSlice.reducer;
