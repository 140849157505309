import React from "react";
import "./footer.scss";
import WhiteLogo from "../../../assets/images/big_logo_white.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="p-50-y">
      <div className="container-md">
        <div className="startFooter bg-third border-radius-20 p-sm-80 p-40">
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="companyLogo width-lg-200 width-150 d-sm-none m-50-bottom m-auto">
                <img src={WhiteLogo} alt="" />
              </div>
              <div className="companyDetails cursor-pointer d-flex">
                <div className="companyDetailsIcon fs-lg-30 fs-24 text-light m-10-right">
                  <i class="ph ph-map-pin"></i>
                </div>
                <div className="companyDetailsText fs-lg-16 fs-14">
                  <div className="text-light fw-500">Office Address</div>
                  <div className="footerSubDetail m-5-top fw-300 lh-26">
                    <Link
                      className=" text-gray"
                      target="_blank"
                      to={`https://maps.app.goo.gl/cY6vNxuNJhnb8GDo9`}
                    >
                      520, Sahajanand Hub, Gaghpur Road, Opp. N.C. Takker
                      School, Sarathana Jakatnaka, Surat - 395006.
                    </Link>
                  </div>
                </div>
              </div>
              <div className="companyDetails cursor-pointer m-sm-50-y m-30-y d-flex">
                <div className="companyDetailsIcon fs-lg-30 fs-24 text-light m-10-right">
                  <i class="ph ph-envelope-simple-open"></i>
                </div>
                <div className="companyDetailsText fs-lg-16 fs-14">
                  <div className="text-light fw-500">Email Us</div>
                  <div className="footerSubDetail text-gray m-5-top fw-300 lh-26">
                    <Link
                      className=" text-gray"
                      target="_blank"
                      to={`mailto:multikodit@gmail.com`}
                    >
                      multikodit@gmail.com
                    </Link>
                  </div>
                </div>
              </div>
              <div className="companyDetails cursor-pointer d-flex">
                <div className="companyDetailsIcon fs-lg-30 fs-24 text-light m-10-right">
                  <i class="ph ph-phone"></i>
                </div>
                <div className="companyDetailsText fs-lg-16 fs-14">
                  <div className="text-light fw-500">Call Us</div>
                  <div className="footerSubDetail text-gray m-5-top fw-300 lh-26">
                    <Link
                      className=" text-gray"
                      target="_blank"
                      to={`tel:8866625957`}
                    >
                      <div>(+91) 88666 25957</div>
                    </Link>
                    <Link
                      className=" text-gray"
                      target="_blank"
                      to={`tel:6358163236`}
                    >
                      <div>(+91) 63581 63236</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 d-xl-block d-none"></div>
            <div className="col-sm-6 col-12 m-sm-0-top">
              <div className="companyLogo width-lg-200 width-150 d-sm-block d-none">
                <img src={WhiteLogo} alt="" />
              </div>
              <div className="companySocialMedia m-50-top d-flex flex-wrap justify-content-sm-start justify-content-center">
                <Link to={"/"} target="_blank">
                  <div className="socialMediaBox trans-3 cursor-pointer m-10-right m-10-bottom hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center">
                    <i class="fa-brands fa-whatsapp"></i>
                  </div>
                </Link>
                <Link to={"/"} target="_blank">
                  <div className="socialMediaBox trans-3 cursor-pointer m-10-right m-10-bottom hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center">
                    <i class="fa-brands fa-instagram"></i>
                  </div>
                </Link>
                <Link to={"/"} target="_blank">
                  <div className="socialMediaBox trans-3 cursor-pointer m-10-right m-10-bottom hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center">
                    <i class="fa-brands fa-linkedin"></i>
                  </div>
                </Link>
                <Link to={"/"} target="_blank">
                  <div className="socialMediaBox trans-3 cursor-pointer m-10-right m-10-bottom hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center">
                    <i class="fa-brands fa-facebook-f"></i>
                  </div>
                </Link>
                {/* <Link to={"/"} target="_blank">
                <div className="socialMediaBox trans-3 cursor-pointer m-10-right m-10-bottom hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center">
                  <i class="fa-brands fa-youtube"></i>
                </div>
              </Link> */}
              </div>

              <div className="copyrightOfCompany text-light m-30-top fs-lg-14 fs-12 fw-300 text-sm-start text-center">
                <span className="text-gray">2024 © </span>
                <span>Multikod Infosys and Advance Training</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
